export const neighborhood =(currentPage,total, n) =>{
    const start = currentPage-n;
    const stop = currentPage +n;
    const step =1;
    const arr = Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );

    return arr.filter(a=>a>=1 && a<=total);
}
import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Container, Row, Col, Pagination } from 'react-bootstrap';
import Bio from '../components/bio';
import { neighborhood } from '../utils/utils';
const BlogList = (props) => {
    const { currentPage, numPages } = props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "/" : "/"+(currentPage - 1);
    const nextPage = "/"+(currentPage + 1);
    const firstPage = "/"; 
    const lastPage = "/"+numPages; 
    const showNeighbors = 3;
    return (
      <Layout pageInfo={{ pageName: "index" }}>
        <SEO title="Home" keywords={[`gatsby`, `react`, `samip subedi`, `Samip subedi's blog`, `tech kunja`, `tech kunja samip`,`react developer nepal`, `gatsby developer nepal`]} />
        <Container>
          <Row className="mb-5">
            <Col>
              <Bio />
            </Col>
          </Row>
          <Row>
            <Col>
              {props.data.allContentfulBlog.nodes.map(blog => (
                <div key={blog.id}>
                  <Link to={`/blog/${blog.slug}`}>
                    <h2>{blog.title}</h2>
                  </Link>
                  <p>
                    <small>{blog.shortContent.shortContent}</small>
                  </p>
                </div>
              ))}
            </Col>
          </Row>
          {numPages > 1 && (
            <Row>
              <Col>
                <Pagination>
                  <Pagination.First
                    disabled={isFirst}
                    onClick={() => {
                      if (!isFirst) {
                        props.navigate(firstPage)
                      }
                    }}
                  />
                  <Pagination.Prev
                    disabled={isFirst}
                    onClick={() => {
                      if (!isFirst) {
                        props.navigate(prevPage)
                      }
                    }}
                  />
                  {!isFirst &&
                    neighborhood(currentPage, numPages, showNeighbors).length <
                      numPages && <Pagination.Ellipsis />}
                  {neighborhood(currentPage, numPages, showNeighbors).map(
                    item => (
                      <Pagination.Item
                        disabled={currentPage}
                        key={`pagination-number${item}`}
                        onClick={e => {
                          props.navigate(`/${item === 0 ? "" : item}`)
                        }}
                      >
                        {item}
                      </Pagination.Item>
                    )
                  )}
                  {!isLast &&
                    neighborhood(currentPage, numPages, showNeighbors).length <
                      numPages && <Pagination.Ellipsis />}
                  <Pagination.Next
                    disabled={isLast}
                    onClick={() => {
                      if (!isLast) {
                        props.navigate(nextPage)
                      }
                    }}
                  />
                  <Pagination.Last
                    disabled={isLast}
                    onClick={() => {
                      if (!isLast) {
                        props.navigate(lastPage)
                      }
                    }}
                  />
                </Pagination>
              </Col>
            </Row>
          )}
        </Container>
      </Layout>
    )
}

export default BlogList;
export const pageQuery = graphql`
  query BlogPosts($skip: Int!, $limit: Int!) {
    allContentfulBlog(limit: $limit, skip: $skip) {
      totalCount
      nodes {
        id
        title
        slug
        createdAt
        shortContent {
          shortContent
        }
      }
    }
  }
`
